import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LocalSearchService {

  //public subject$: BehaviorSubject<string> = new BehaviorSubject<string>('')
  public subject$: Subject<string> = new Subject<string>()

  public search(value: string): void {
   this.subject$.next(value)
  }

}
