import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { Observable, throwError } from 'rxjs'
import { Customer } from '@core/models/sales/customer.model'
import { TableLazyLoadEvent } from 'primeng/table'
import { DataService } from '@core/interfaces/data-service'

@Injectable({ providedIn: 'root' })
export class CustomerService extends ApiService implements DataService {

  protected override endpoint: string = 'sales/customers'

  public getCollection(event: TableLazyLoadEvent): Observable<Customer[]> {
    return this._getCollection<Customer>(event)
  }

  public getCollectionSelect(filter: string, event: TableLazyLoadEvent | null = null): Observable<Customer[]> {
    return this._getCollectionSelect<Customer>(filter, event, true)
  }

  public getItem(id: number): Observable<Customer> {
    return this._getItem<Customer>(id)
  }

  public getTotalItems(): number {
    return this._getTotalItems()
  }

  public create(entity: Customer): Observable<Customer> {
    if (entity.id && entity.id > 0)
      return this.update(entity)

    return this._post(entity)
  }

  public update(entity: Customer): Observable<Customer> {
    if (entity.id && entity.id > 0)
      return this._patch(entity.id, entity)

    return throwError(() => new Error('Entity has invalid id'))
  }

  public delete(entity: Customer): Observable<any> {
    if (entity.id && entity.id > 0)
      return this._delete(entity.id)

    return throwError(() => new Error('Entity has invalid id'))
  }
}
