import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Filter } from '@core/interfaces/filter'
import { FormsModule } from '@angular/forms'
import { CustomerSelectComponent } from '@app/components/form/entity-search-selects/customer-select/customer-select.component'

@Component({
  selector: 'tb-customer-filter',
  standalone: true,
  imports: [
    FormsModule,
    CustomerSelectComponent
  ],
  templateUrl: 'customer-filter.component.html'
})
export class CustomerFilterComponent {

  @Input() filter!: Filter
  @Input() label: string = 'Kunde'

  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>()

  protected value: any

  onCustomerFilterChanged(event: any): void {
    this.onFilterChange.emit(event?.id)
  }
}
