import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { CustomerService } from '@core/data-services/sales/customer.service'
import { EntitySearchSelectComponent } from '@app/components/form/entity-search-select/entity-search-select.component'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { Customer } from '@core/models/sales/customer.model'

@Component({
  selector: 'tb-customer-select',
  standalone: true,
  templateUrl: 'customer-select.component.html',
  imports: [
    EntitySearchSelectComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSelectComponent),
      multi: true
    },
  ],
})
export class CustomerSelectComponent extends BaseFormControlComponent {

  @Input() override label: string = ''
  @Input() loadFullEntityOnSelect: boolean = true

  @Output() onCustomerSelected: EventEmitter<Customer | null> = new EventEmitter<Customer | null>()

  constructor(
    protected customerService: CustomerService,
  ) {
    super(...BaseFormControlComponent.deps())
  }

  protected onSelected(data: any): void {
    this.value = data
    this.onCustomerSelected.emit(this.value || null)
  }

}
