export enum ColumnFormat {
  Enum,
  Currency,
  String,
  Date,
  Integer ,
  Decimal,
  Bool,
  Percentage,
  Items,
  Address
}

export interface Column {
  field: string | ((row: any) => string)
  label: string
  sortable?: boolean
  filterable?: boolean
  format?: ColumnFormat
  enumName?: string
  maxLength?: number
  tooltip?: boolean
  appendix?: string
  width?: string
  infoTooltip?: string
  hasColumnFilterDate?: boolean
  noWrap?: boolean
}
