import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { TableLazyLoadEvent } from 'primeng/table'
import { DataService } from '@core/interfaces/data-service'

@Injectable({ providedIn: 'root' })
export class EntityTableStateService {

	private map: Map<string, BehaviorSubject<TableLazyLoadEvent | null>> = new Map<string, BehaviorSubject<TableLazyLoadEvent | null>>()

	public setLastEvent(key: string, value: TableLazyLoadEvent | null): void {
		let lastEvent$: any = this.map.get(key)

		if (!lastEvent$) {
			lastEvent$ = new BehaviorSubject<TableLazyLoadEvent | null>(null)
			this.map.set(key, lastEvent$)
		}

		lastEvent$.next(value)
	}

	public getLastEvent(key: string): any {
		return this.map.get(key)
	}

	public getKey(dataService: DataService): string {
		return (dataService as any)['endpoint'] || ''
	}

}
