import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Filter } from '@core/interfaces/filter'
import { FormsModule } from '@angular/forms'
import { IntegerInputComponent } from '@app/components/form/numeric-inputs/integer-input/integer-input.component'

@Component({
  selector: 'tb-numeric-filter',
  standalone: true,
  imports: [
    FormsModule,
    IntegerInputComponent
  ],
  templateUrl: './numeric-filter.component.html'
})
export class NumericFilterComponent {

  @Input() filter!: Filter
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>()

  protected value: any

  onNumericFilterChanged(event: any): void {
    this.onFilterChange.emit(event)
  }
}
