<tb-entity-select
	optionLabel="firstName"
	[appendTo]="appendTo"
	[value]="_value"
  [dataService]="userService"
  [label]="label"
  (onSelected)="onSelected($event)"
	[invalid]="invalid"
	[disabled]="disabled"
	[hideErrorMessages]="hideErrorMessages"
	[additionalFilters]="{ 'enabled': { value: true } }"
/>
