<tb-entity-search-select
  label="Kunde"
  optionLabel="company"
	labelPrefixKey="number"
	openUrl="/sales/customers/"
	[dataService]="customerService"
	[required]="required"
	[disabled]="disabled"
	[value]="_value"
	[invalid]="invalid"
	[loadFullEntityOnSelect]="loadFullEntityOnSelect"
	(onSelected)="onSelected($event)"
/>
